import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './HomePage.module.css';
import Logo from '../shared/Logo';
import OAuthButtons from '../auth/OAuthButtons';

export default function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    // This prevents scrolling on the entire document
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    };
  }, []);

  return (
    <div className={styles.fullPage}>
      <div className={styles.centeredContent}>
        <Logo className={styles.logo} width={180} />
        <h1 className={styles.title}>
          <span className={styles.highlight}>Connecting talent</span> with opportunity
        </h1>
        <p className={styles.subtitle}>A platform built for freelance developers</p>
        <p className={styles.description}>
          Build, showcase, and get hired for automation tools and AI-powered apps.
        </p>
        <div className={styles.buttonGroup}>
          <button className={styles.login} onClick={() => navigate('/login')}>Login</button>
          <button className={styles.register} onClick={() => navigate('/register')}>Register</button>
        </div>
        <div className={styles.oauthWrapper}>
          <OAuthButtons />
        </div>
      </div>
    </div>
  );
}